// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import 'amfe-flexible'
import Vant from 'vant';
import 'vant/lib/index.css';
import diretivesObj from '@/assets/js/premission'
Vue.use(diretivesObj)
Vue.use(Vant);
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  let phoneToken = localStorage.getItem("phone")
  let ZJphoneToken = localStorage.getItem("ZJphone")
  let THSphoneToken = localStorage.getItem("THSphone")
  if (to.path == '/Register' && phoneToken && phoneToken != "null") {
    next({
      path: '/Received'
    })
  } else if (to.path == '/ZJactivity' && ZJphoneToken && ZJphoneToken != "null") {
    next({
      path: '/ZJReceived'
    })
  } else if (to.path == '/THSactivity' && THSphoneToken && THSphoneToken != "null") {
    next({
      path: '/THSReceived'
    })
  } else {
    next()
  }
});
new Vue({
  router,
  render: h => h(App)
}).$mount("#app")