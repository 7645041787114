
import Vue from 'vue'
function buttonRole(key) {
    let arr = JSON.parse(localStorage.getItem("btnPermission"))
    let index = arr.indexOf(key)
    if (index > -1) {
        return true;
    } else {
        return false;
    }
}
const has = Vue.directive('has', {
    inserted: (el, binding) => {
        let permission = binding.value.toString();
        if (permission) {
            let hasPermission = buttonRole(permission);
            if (!hasPermission) {
                localStorage.setItem("superflag", false)
                el.style.display="none"
                // el.parentNode && el.parentNode.removeChild(el);
            } else {
                localStorage.setItem("superflag", true)
            }
        }
    }
})
export default {
    has
}


