import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
export default new Router({
  // mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/page/home'),
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/page/login'),
    },
    {
      path: '/clueview',//线索列表
      name: 'clueview',
      component: () => import('@/page/clueview'),
    },
    {
      path: '/clueviewadd',//线索列表/添加编辑
      name: 'clueviewadd',
      component: () => import('@/page/clueview/add'),
    },
    {
      path: '/clueviewdetail',//线索列表/详情
      name: 'clueviewdetail',
      component: () => import('@/page/clueview/detail'),
    },
    {
      path: '/clueviewsurvey',//线索列表/调研
      name: 'clueviewsurvey',
      component: () => import('@/page/clueview/survey'),
    },
    {
      path: '/customer',//客户列表
      name: 'customer',
      component: () => import('@/page/customer'),
    },
    {
      path: '/customeradd',//客户列表/新增
      name: 'customeradd',
      component: () => import('@/page/customer/add'),
    },
    {
      path: '/customerdetail',//客户列表/详情
      name: 'customerdetail',
      component: () => import('@/page/customer/detail'),
    },

    {
      path: '/customersurvey',//客户列表/调研
      name: 'customersurvey',
      component: () => import('@/page/customer/survey'),
    },
    { path: '*', redirect: '/' },
  ]
})
