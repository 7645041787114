<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import VConsole from "vconsole";
// let vConsole = new VConsole();
export default {
  name: "App",
  created() {},
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
body,
html {
  width: 100%;
  height: 100%;
  font-size: 24px;
  font-family: HYQiHei-EZS, HYQiHei;
}
#app {
  width: 100%;
  height: 100%;
}
.detailview {
  .van-field__label {
    width: 200px;
    text-align: right;
  }
}
</style>
